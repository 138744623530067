.container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 350px;
  height: 240px;
  margin: auto;
}

.loginFormFooter {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  width: 100%;

  div {
    flex: 1;
  }
}

.link {
  font-size: 1.2rem;
  float: right;
}

.testDisappear {
  display: flex;
}